import React from "react"

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

export const onRenderBody = ({ setHtmlAttributes, setPostBodyComponents }) => {
    setPostBodyComponents([
        <script key="spotify-pixel-event-tag" dangerouslySetInnerHTML={{
            __html:
                `
        // Installation script generated by Ad Analytics
        (function(w, d){
          var id='spdt-capture', n='script';
          if (!d.getElementById(id)) {
            w.spdt =
              w.spdt ||
              function() {
                (w.spdt.q = w.spdt.q || []).push(arguments);
              };
            var e = d.createElement(n); e.id = id; e.async=1;
            e.src = 'https://pixel.byspotify.com/ping.min.js';
            var s = d.getElementsByTagName(n)[0];
            s.parentNode.insertBefore(e, s);
          }
          w.spdt('conf', { key: '2012ba2ea7fe438d92ded9f12fc0960e' });
          w.spdt('view');
        })(window, document);`
        }}>
        </script>
    ])
    setHtmlAttributes({ lang: "en" })
}
